import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AddressNav from "../TaskList/AddressNav";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Help Me444.png";
import { AdminContext } from "../../AdminContext"; // Import AdminContext

const API_BASE_URL = "https://inifintusdev.tech/api";

// Utility functions
const generateUniqueId = () => `INV-${uuidv4()}`;

const fetchClosestDriver = async (lat, lng) => {
  if (!lat || !lng) {
    console.error("Invalid shop address");
    return null;
  }
  try {
    const response = await axios.get(`${API_BASE_URL}/closest-driver`, {
      params: { lat, lng },
    });
    console.log("Closest driver fetched successfully:", response.data);
    return response.data.driver.id;
  } catch (error) {
    console.error("Error fetching closest driver:", error);
    return null;
  }
};

const AddTask = () => {
  const [previousTasks, setPreviousTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [regions, setRegions] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [device, setDevice] = useState("");
  const { admin, logout } = useContext(AdminContext); // Use AdminContext
  const [enableButton, setEnableButton] = useState(true);
  const [form, setForm] = useState({
    device_id: null,
    user_id: null,
    title: "",
    comment: "",
    priority: null,
    status: "0",
    invoice_number: generateUniqueId(),
    pickup_address: "",
    pickup_address_lat: "",
    pickup_address_lng: "",
    pickup_time_from: null,
    pickup_time_to: null,
    delivery_address: "",
    delivery_address_lat: "",
    delivery_address_lng: "",
    delivery_time_from: null,
    delivery_time_to: null,
    delivery_region: null,
    client_phone_number: "",
    restaurantId: 1,
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedForm = {
      ...form,
      device_id: device,
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/add_task_admin`,
        updatedForm,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Task added successfully:", response.data);
      alert("تم قبول طلبك");
      navigate("/admin/homepage");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleAddressSelect = (selectedAddress) => {
    setForm((prevForm) => ({
      ...prevForm,
      delivery_address: selectedAddress.value,
      delivery_address_lat: selectedAddress.lat || "",
      delivery_address_lng: selectedAddress.lng || "",
      delivery_region: selectedAddress.id || "",
    }));

    handleClose();
  };

  useEffect(() => {
    axios
      .get("https://inifintusdev.tech/api/get_restaurants")
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setRestaurants(response.data);
        }
      })
      .catch((error) => console.log(error));

    axios
      .get("https://inifintusdev.tech/api/get_regions")
      .then((response) => {
        if (response.data) {
          setRegions(response.data);
          console.log("Regions fetched successfully:", response.data);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (device && form.delivery_region && form.delivery_address) {
      setEnableButton(false);
    }
  }, [device, form.delivery_region, form.delivery_address]);

  const handleRestaurantChange = (e) => {
    const selectedRestaurantId = e.target.value;
    const selectedRestaurant = restaurants.find(
      (restaurant) => restaurant.id === selectedRestaurantId
    );

    if (!selectedRestaurant) {
      console.error("Selected restaurant not found");
      return;
    }

    const [pickupAddressLat, pickupAddressLng] = selectedRestaurant.address
      .split(",")
      .map(String);

    setForm((prevForm) => ({
      ...prevForm,
      restaurantId: selectedRestaurantId,
      pickup_address: selectedRestaurant.detailedAddress,
      pickup_address_lat: pickupAddressLat,
      pickup_address_lng: pickupAddressLng,
    }));

    const fetchData = async () => {
      const closestDriver = await fetchClosestDriver(
        pickupAddressLat,
        pickupAddressLng
      );
      if (closestDriver) {
        setDevice(closestDriver);
      }
    };

    fetchData();
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!admin) {
    navigate("/admin"); // Redirect to admin login if not logged in
    return null;
  }

  return (
    <div>
      <div style={{ marginTop: 120 }} >
        <div className="content">
          <div className="nav">
            <Container
              maxWidth="sm"
              sx={{
                // bgcolor: "background.default",
                color: "text.primary",
                padding: 3,
                borderRadius: 2,
                border: "solid black 1px",
                mt: 8,
              }}
            >
              {/* <Button
                                sx={{ mb: "10px" }}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                عودة
                            </Button> */}
              {/* <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="secondary"
                onClick={logout}
              >
                Logout
              </Button> */}

              <Button
                sx={{ display: "block", mb: 2, mx: "auto" }}
                variant="contained"
                onClick={handleClickOpen}
              >
                اختار عنوان التسليم
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    height: "80vh",
                    width: "60vw",
                  },
                }}
              >
                <DialogContent>
                  <AddressNav onAddressSelect={handleAddressSelect} />
                </DialogContent>
              </Dialog>

              <form onSubmit={handleSubmit}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="رقم الهاتف للعميل"
                    name="client_phone_number"
                    value={form.client_phone_number}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    // sx={{ bgcolor: "background.paper" }}
                    required
                  />
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item xs={12} sm={6}>
                    <FormControl margin="normal">
                      <InputLabel id="restaurant-label">مطاعم</InputLabel>
                      <Select
                        labelId="restaurant-label"
                        id="restaurantId"
                        name="restaurantId"
                        value={form.restaurantId}
                        onChange={handleRestaurantChange}
                        variant="outlined"
                        sx={{ width: "220px" }}
                      >
                        <MenuItem value="">
                          <em>اختر مطعم</em>
                        </MenuItem>
                        {restaurants.map((restaurant) => (
                          <MenuItem key={restaurant.id} value={restaurant.id}>
                            {restaurant.commercialName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      sx={{ height: "56px", width: "100%" }}
                      variant="contained"
                      color="success"
                      type="submit"
                      disabled={enableButton}
                    >
                      إرسال
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
