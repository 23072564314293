// TasksTable.js
import React, {useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    FormLabel,
    Typography,
    Box,
    TableSortLabel,
    TextField, Button, Select, MenuItem, FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Switch} from "@mui/joy";
import FormControlLabel from "@mui/material/FormControlLabel";

const TasksTable = ({
                        tasks,
                        taskPage,
                        taskRowsPerPage,
                        handleTaskChangePage,
                        handleTaskChangeRowsPerPage,
                        itemSum,
                        statusListInArabic,
                    }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("delivery_address");
    const [searchQuery, setSearchQuery] = useState("");
    const [open, setOpen] = React.useState(false);
    const [driverId, setDriverId] = useState("");
    const [drivers, setDrivers] = useState([]);
    const [taskId, setTaskId] = useState("");

    useEffect(() => {
        axios.get('https://inifintusdev.tech/api/all_drivers')
            .then((res) => {
                setDrivers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleClickOpen = (driverId, taskId) => {
        setDriverId(driverId);
        setTaskId(taskId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        //     reload the tasks
        // window.location.reload();
    };

    const filteredTasks = tasks.filter(
        (task) =>
            task.delivery_address.toLowerCase().includes(searchQuery.toLowerCase()) ||
            task.Restaurant.commercialName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            task.price.toString().includes(searchQuery) ||
            task.createdAt.toLowerCase().includes(searchQuery.toLowerCase()) ||
            task.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            statusListInArabic[task.status].includes(searchQuery)
    );

    const sortedTasks = filteredTasks.sort((a, b) => {
        if (order === "asc") {
            return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
        } else {
            return a[orderBy] > b[orderBy] ? -1 : a[orderBy] < b[orderBy] ? 1 : 0;
        }
    });

    return (
        <Box sx={{mb: 4}}>
            <Typography variant="h4" align="center" gutterBottom>
                قائمة المهام
            </Typography>
            <TextField
                fullWidth
                label="ابحث"
                id="search"
                value={searchQuery}
                onChange={handleSearchChange}
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "delivery_address"}
                                    direction={orderBy === "delivery_address" ? order : "asc"}
                                    onClick={() => handleRequestSort("delivery_address")}
                                >
                                    موقع المستلم
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "Restaurant.commercialName"}
                                    direction={orderBy === "Restaurant.commercialName" ? order : "asc"}
                                    onClick={() => handleRequestSort("Restaurant.commercialName")}
                                >
                                    اسم المطعم
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "price"}
                                    direction={orderBy === "price" ? order : "asc"}
                                    onClick={() => handleRequestSort("price")}
                                >
                                    السعر
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "createdAt"}
                                    direction={orderBy === "createdAt" ? order : "asc"}
                                    onClick={() => handleRequestSort("createdAt")}
                                >
                                    تاريخ الإنشاء
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "driver_name"}
                                    direction={orderBy === "driver_name" ? order : "asc"}
                                    onClick={() => handleRequestSort("driver_name")}
                                >
                                    اسم السائق
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "status"}
                                    direction={orderBy === "status" ? order : "asc"}
                                    onClick={() => handleRequestSort("status")}
                                >
                                    الحالة
                                </TableSortLabel>
                            </TableCell>
                            {/*    change driver*/}
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                تغيير السائق
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTasks
                            .slice(
                                taskPage * taskRowsPerPage,
                                taskPage * taskRowsPerPage + taskRowsPerPage
                            )
                            .map((task, index) => (
                                <TableRow
                                    key={task.id}
                                    sx={{
                                        backgroundColor:
                                            task.status === "0"
                                                ? "cyan"
                                                : task.status === "1"
                                                    ? "yellow"
                                                    : task.status === "2"
                                                        ? "orange"
                                                        : task.status === "3"
                                                            ? "green"
                                                            : task.status === "9"
                                                                ? "red"
                                                                : task.status === "4"
                                                                    ? "grey"
                                                                    : "transparent",
                                        border: "1px solid black",
                                    }}
                                >
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {task.delivery_address}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {task.Restaurant.commercialName}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {task.price}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {task.createdAt}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>

                                        {task.Driver?.username ? task.Driver.username : task.driver_name}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {statusListInArabic[task.status]}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                if (!task?.Driver) {
                                                    handleClickOpen(task.driver_id, task.id)
                                                } else {
                                                    handleClickOpen(task?.Driver?.id , task.id);
                                                }
                                            }}
                                        >
                                            تغيير السائق
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <FormLabel
                    style={{
                        display: "flex",
                        position: "absolute",
                        marginLeft: 385,
                        marginTop: 13,
                    }}
                >
                    المجموع: {itemSum}
                </FormLabel>

                <TablePagination
                    rowsPerPageOptions={[25, 100, 500]}
                    component="div"
                    count={filteredTasks.length}
                    rowsPerPage={taskRowsPerPage}
                    page={taskPage}
                    onPageChange={handleTaskChangePage}
                    onRowsPerPageChange={handleTaskChangeRowsPerPage}
                />
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        axios.put(`https://inifintusdev.tech/api/update_task_driver/${taskId}`,
                            {
                                driverId: driverId,
                            }
                        )
                            .then((res) => {
                                console.log(res);
                                handleClose();
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    },
                }}
            >
                {/*change and not fix*/}
                <DialogTitle>تعديل السائق</DialogTitle>
                <DialogContent>
                    <FormControl sx={{mt: 2, minWidth: 250}}>
                        <Select
                            autoFocus
                            value={driverId}
                            label="Drivers"
                            sx={{minWidth: 200}}
                            onChange={(event) => {
                                setDriverId(event.target.value);
                            }}
                            inputProps={
                                {
                                    name: 'driverId',
                                    id: 'driverId',
                                }
                            }
                        >
                            {drivers.map((driver) => (
                                <MenuItem key={driver.id} value={driver.id}>{driver.username}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>إلغاء</Button>
                    <Button type="submit">تعديل</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default TasksTable;
//
