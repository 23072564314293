// AdminHomePage.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Container, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import TasksTable from "./TasksTable";
import TableSortLabel from "@mui/material/TableSortLabel";

const AdminHomePage = () => {
  const [tasks, setTasks] = useState([]);
  const [itemSum, setItemSum] = useState(0);
  const [previousTasks, setPreviousTasks] = useState([]);
  const [totalTaskPrice, setTotalTaskPrice] = useState(0);
  const [count, setCount] = useState(0);

  const baseUrl = "https://inifintusdev.tech/api";

  const [taskPage, setTaskPage] = useState(0);
  const [taskRowsPerPage, setTaskRowsPerPage] = useState(25);

  const statusListInArabic = {
    3: "تم التسليم",
    0: "جاهز من المطعم",
    1: "قيد التوصيل",
    2: "تم الاستلام",
    9: "تم إلغاء الطلب",
    4: "تم إرجاع الطلب",
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(
          "https://inifintusdev.tech/api/get_tasks_admin"
        );

        const newTasks = response.data;
        newTasks.forEach((newTask) => {
          const previousTask = previousTasks.find(
            (task) => task.id === newTask.id
          );
          if (previousTask && previousTask.status !== newTask.status) {
            if (newTask.status === "3") {
              toast.info(`تم استلام الطلب`, {
                className: "toast-custom",
              });
            }
            if (newTask.status === "9") {
              toast.error(`تم رفض الطلب`, {
                className: "toast-custom-error",
              });
            }
          }
        });
        newTasks.sort((a, b) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
        setTasks(newTasks);
        setPreviousTasks(newTasks);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
    const interval = setInterval(() => {
      fetchTasks();
      setCount((prevCount) => prevCount + 1);
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [previousTasks, count]);

  useEffect(() => {
    const totalSum = tasks.reduce((accumulator, item) => {
      return accumulator + item.price;
    }, 0);
    setItemSum(totalSum);
  }, [tasks]);

  return (
    <div>
      <div style={{ marginTop: 120 }} className="container">
        <div className="content">
          <div className="nav">
            <Container
              sx={{
                height: "auto",
                width: "80vw",
                mt: 5,
                border: "solid",
                borderRadius: "25px",
                padding: 2,
              }}
            >
              {/* <Typography
                variant="h4"
                sx={{
                  flexGrow: 1,
                  p: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                صفحة الادمن الرئيسية
              </Typography> */}
              {/* <Box display="flex" mb={3} mt={2} alignItems="center" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                               
                            </Box> */}

              <TasksTable
                tasks={tasks}
                taskPage={taskPage}
                taskRowsPerPage={taskRowsPerPage}
                handleTaskChangePage={(event, newPage) => setTaskPage(newPage)}
                handleTaskChangeRowsPerPage={(event) => {
                  setTaskRowsPerPage(parseInt(event.target.value, 10));
                  setTaskPage(0);
                }}
                itemSum={itemSum}
                statusListInArabic={statusListInArabic}
              />
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHomePage;
