import React, { useState } from "react";
import axios from "axios";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import logo from "../../Assets/Help Me444.png";
import TaskPanel from "../TaskPanel/TaskPanel";
import { useNavigate } from "react-router-dom";

const AddTask = () => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [lng, setLng] = useState("");
  const [lat, setLat] = useState("");
  const [previousTasks, setPreviousTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const baseUrl = "https://inifintusdev.tech/api"; // Replace with your actual base URL
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/admin/homepage");
    
    const newRestaurant = {
      key,
      value,
      lng,
      lat,
    };


    try {
      const response = await axios.post(
        "https://inifintusdev.tech/api/add_region",
        newRestaurant
      );

      if (response.status === 200) {
        console.log("Restaurant created successfully");
      } else {
        console.error("Failed to create restaurant");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  // const triggerNotification = (task) => {
  //   // Implement your notification logic here
  //   alert(`Task status changed: ${task.title} is now ${task.status}`);
  // };

  // const handleStatusChange = async (taskId) => {
  //   let task = tasks.find((task) => task.id === taskId);
  //   console.log(`Task ${task}`);
  //   try {
  //     await axios.put(`${baseUrl}/edit_task`, {
  //       ...task,
  //       status: 3,
  //     });
  //     setTasks((prevTasks) =>
  //       prevTasks.map((task) =>
  //         task.id === taskId ? { ...task, status: 3 } : task
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Error updating task status:", error);
  //   }
  // };

  return (
    <div>
      <img
        src={logo}
        style={{
          position: "absolute",
          display: "flex",
          top: -70,
          left: 30,
          width: "12%",
          height: "40%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        alt="Italian Trulli"
      />
      <div style={{ marginTop: 120 }} className="container">
        <div className="content">
          <div className="nav">
            {/*<TaskPanel*/}
            {/*  className="taskCard"*/}
            {/*  baseUrl={baseUrl}*/}
            {/*  previousTasks={previousTasks}*/}
            {/*  setPreviousTasks={setPreviousTasks}*/}
            {/*  triggerNotification={triggerNotification}*/}
            {/*  handleStatusChange={handleStatusChange}*/}
            {/*/>*/}
            <Container
              maxWidth="sm"
              sx={{
                mt: 5,
                border: "1px solid black",
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <Typography variant="h4" gutterBottom>
                {/*add region*/}
                اضافة منطقة
                {/* <Button
                  sx={{ ml: "330px" }}
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  عودة
                </Button> */}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    label="Key"
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    label="Value"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    label="Longitude"
                    value={lng}
                    onChange={(e) => setLng(e.target.value)}
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ mb: 3 }}>
                  <TextField
                    fullWidth
                    label="Latitude"
                    value={lat}
                    onChange={(e) => setLat(e.target.value)}
                    variant="outlined"
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  fullWidth
                >
                  {/*Add Region*/}
                  اضافة منطقة
                </Button>
              </form>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
