import React, {useState, useEffect, useContext} from "react";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Container,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogContent,
} from "@mui/material";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import AddressNav from "../TaskList/AddressNav";
import {useNavigate} from "react-router-dom";
import logo from "../../Assets/Help Me444.png";
import {AdminContext} from "../../AdminContext";
import {MapContainer, TileLayer, Marker, useMapEvents} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {Modal} from "@mui/joy";

const API_BASE_URL = "https://inifintusdev.tech/api";

// Utility functions
const generateUniqueId = () => `INV-${uuidv4()}`;
const fetchClosestDriver = async (lat, lng) => {
    if (!lat || !lng) {
        console.error("Invalid shop address");
        return null;
    }
    try {
        const response = await axios.get(`${API_BASE_URL}/closest-driver`, {
            params: {lat, lng},
        });
        console.log("Closest driver fetched successfully:", response.data);
        return response.data.driver.id;
    } catch (error) {
        console.error("Error fetching closest driver:", error);
        return null;
    }
};

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const AddTask = () => {
    const [device, setDevice] = useState("");
    const {admin, logout} = useContext(AdminContext); // Use AdminContext
    const [enableButton, setEnableButton] = useState(true);
    const [selectedPosition, setSelectedPosition] = useState([31.5123123, 35.1231321321312]);
    const [form, setForm] = useState({
        device_id: null,
        user_id: null,
        title: "",
        comment: "",
        priority: null,
        status: "0",
        invoice_number: generateUniqueId(),
        pickup_address: "",
        pickup_address_lat: "",
        pickup_address_lng: "",
        pickup_time_from: null,
        pickup_time_to: null,
        delivery_address: "",
        delivery_address_lat: "",
        delivery_address_lng: "",
        delivery_time_from: null,
        delivery_time_to: null,
        delivery_region: null,
        client_phone_number: "",
        restaurantId: 1,
    });
    const [customBoolean, setCustomBoolean] = useState(false);
    const PickUpMarker = () => {
        useMapEvents({
            click(e) {
                let lat = e.latlng.lat;
                let lng = e.latlng.lng;
                // make values string
                setSelectedPosition([lat, lng]);
                handleCloseMap();
                fetchClosestDriver(selectedPosition[0], selectedPosition[1]).then((driver) => {
                    setDevice(driver);
                })
                setForm((prevForm) => ({
                    ...prevForm,
                    pickup_address_lat: String(lat),
                    pickup_address_lng: String(lng),
                    pickup_address: "custom",
                    delivery_region: 9999,
                }));
            },
        });

        return selectedPosition ? (
            <Marker position={selectedPosition}></Marker>
        ) : null;
    };
    const DeliveryMarker = () => {
        useMapEvents({
            click(e) {
                let lat = e.latlng.lat;
                let lng = e.latlng.lng;
                // make values string
                setSelectedPosition([lat, lng]);
                handleCloseMap();
                setForm((prevForm) => ({
                    ...prevForm,
                    delivery_address_lat: String(lat),
                    delivery_address_lng: String(lng),
                    delivery_address:  "custom",
                }));
            },
        });
        return selectedPosition ? (
            <Marker position={selectedPosition}></Marker>
        ) : null;
    };
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedForm = {
            ...form,
            device_id: device,
        };
        try {
            const response = await axios.post(
                `${API_BASE_URL}/add_task_admin`,
                updatedForm,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            console.log("Task added successfully:", response.data);
            alert("تم قبول طلبك");
            navigate("/admin/homepage");
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (device ) {
            setEnableButton(false);
        }
    }, [device, form.delivery_region, form.delivery_address]);

    const [openMap, setOpenMap] = useState(false);

    const handleOpen = (bool) => {
        if (bool === 'pickup') {
            setCustomBoolean(false);
        }
        if (bool === 'delivery') {
            setCustomBoolean(true);
        }
        setOpenMap(true);
    };
    const handleCloseMap = () => setOpenMap(false);

    if (!admin) {
        navigate("/admin"); // Redirect to admin login if not logged in
        return null;
    }

    return (
        <div>
            
            <div style={{marginTop: 120}} className="container">
                <div className="content">
                    <div className="nav">
                        <Container
                            maxWidth="md"
                            sx={{
                                // bgcolor: "background.default",
                                color: "text.primary",
                                padding: 3,
                                borderRadius: 2,
                                border: "solid black 1px",
                                mt: 8,
                            }}
                        >
                            {/* <Button
                                sx={{mb: "10px"}}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                عودة
                            </Button> */}
                            <form onSubmit={handleSubmit}>
                                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '5px', gap: '10px'}}>
                                    <Button variant="contained" onClick={() => handleOpen('pickup')}>
                                        اختار منطقة الاستلام
                                    </Button>
                                    <Button variant="contained" onClick={() => handleOpen('delivery')}>
                                        اختار منطقة التسليم
                                    </Button>
                                </Box>
                                <Modal open={openMap} onClose={handleCloseMap}>
                                    <Box sx={{width: 1000, height: 800, margin: "auto", mt: 5}}>
                                        <MapContainer center={selectedPosition} zoom={13}
                                                      style={{height: "100%", width: "100%"}}>
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            />
                                            {customBoolean ? <DeliveryMarker/> : <PickUpMarker/>}
                                        </MapContainer>
                                    </Box>
                                </Modal>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="رقم الهاتف للعميل"
                                        name="client_phone_number"
                                        value={form.client_phone_number}
                                        onChange={handleChange}
                                        fullWidth
                                        variant="outlined"
                                        margin="normal"
                                        // sx={{bgcolor: "background.paper"}}
                                        required
                                    />
                                </Grid>
                                <Grid container direction="row">
                                    <Grid container spacing={2} direction="row">
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                label="السعر"
                                                name="price"
                                                value={form.price}
                                                onChange={handleChange}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                // sx={{bgcolor: "background.paper"}}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <TextField
                                                label="التعليق"
                                                name="comment"
                                                value={form.comment}
                                                onChange={handleChange}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                // sx={{bgcolor: "background.paper"}}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} marginY={1}>
                                        <Button
                                            sx={{height: "56px", width: "100%"}}
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                            disabled={enableButton}
                                        >
                                            إرسال
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddTask;
