import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Login from "./Components/Login/Login";
import Settings from "./Components/Settings/Settings";
import "./App.css";
import OrderCaptain from "./Components/Orders/OrderCaptain";
import TaskForm from "./Components/TaskList/TaskForm";
import Registration from "./Components/Registeration/Register";
import { UserContext, UserProvider } from "./UserContext";
import { AdminContext, AdminProvider } from "./AdminContext";
import Driver from "./Components/Admin/Driver";
import Admin from "./Components/Admin/Admin";
import AdminHomePage from "./Components/Admin/AdminHomePage";
import AddRestaurant from "./Components/Admin/AddRestaurant";
import Pricing from "./Components/Admin/Pricing";
import ProtectedRoute from "./Components/ProtectedRoute";
import AddRegion from "./Components/Admin/AddRegion";
import AddTask from "./Components/Admin/AddTask";
import Total from "./Components/Admin/Total";
import RestaurantsPage from "./Components/Admin/RestaurantsPage";
import ProtectedAdminRoute from "./Components/ProtectedAdminRoute"; // Import the ProtectedAdminRoute
import CreateWallet from "./Components/Wallet/CreateWallet"; // Import the ProtectedAdminRoute
import AddCredits from "./Components/Wallet/AddCredits";
import AddDriver from "./Components/Admin/AddDriver";
import AddDevice from "./Components/Admin/AddDevice";
import AddPackage from "./Components/Admin/AddPackage";
import AdminMap from "./Components/Admin/AdminMap";
import NavBar from "./Components/navbar/Navbar";

const App = () => {
  return (
    <Router>
      <UserProvider>
        <AdminProvider>
          <Main />
        </AdminProvider>
      </UserProvider>
    </Router>
  );
};

const Main = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/" ||
      location.pathname === "/admin"
    ) {
      document.body.classList.add("login-page");
      document.body.classList.remove("other-page");
    } else {
      document.body.classList.add("other-page");
      document.body.classList.remove("login-page");
    }
  }, [location]);

  const showNavBar = !(
    location.pathname === "/" ||
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/settings" ||
    location.pathname === "/ordercaptain" ||
    location.pathname === "/taskform" ||
    location.pathname === "/admin"
  );
  return (
    <>
      {showNavBar && <NavBar />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
      </Routes>
      <Routes>
        <Route
          path="/register"
          element={
            <ProtectedRoute>
              <Registration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ordercaptain"
          element={
            <ProtectedRoute>
              <OrderCaptain />
            </ProtectedRoute>
          }
        />
        <Route
          path="/taskform"
          element={
            <ProtectedRoute>
              <TaskForm />
            </ProtectedRoute>
          }
        />
        {/* <Route element={<ProtectedAdminRoute />}> */}
        <Route path="/admin/total" element={<Total />} />
        <Route path="/admin/homepage" element={<AdminHomePage />} />
        <Route path="/admin/add-restaurant" element={<AddRestaurant />} />
        <Route path="/admin/add-task" element={<AddTask />} />
        <Route path="/admin/pricing" element={<Pricing />} />
        <Route path="/admin/drivers" element={<Driver />} />
        <Route path="/admin/add-region" element={<AddRegion />} />
        <Route path="/admin/restaurants" element={<RestaurantsPage />} />
        <Route path="/admin/create-wallet" element={<CreateWallet />} />
        <Route path="/admin/add-credits" element={<AddCredits />} />
        <Route path="/admin/add-driver" element={<AddDriver />} />
        <Route path="/admin/add-device" element={<AddDevice />} />
        <Route path="/admin/add-package" element={<AddPackage />} />
        {/*  admin/map*/}
        <Route path="/admin/map" element={<AdminMap />} />
        {/* </Route> */}
      </Routes>
    </>
  );
};

export default App;
