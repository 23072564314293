import React, {useState, useEffect} from "react";
import "./Settings.scss";
import TopNavBar from "./TopNavBar/TopNavBar";
import Cars from "./cars/Cars";
import {Button} from "@mui/material";
import Map from "./map/Map";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";

function Settings() {
    const [tasks, setTasks] = useState([]);
    const [previousTasks, setPreviousTasks] = useState([]);
    const navigate = useNavigate();
    const baseUrl = "https://inifintusdev.tech/api";
    const [wallet, setWallet] = useState(0);
    const statusListInArabic = {
        3: "تم التسليم",
        0: "جاهز من المطعم",
        1: "قيد التوصيل",
        2: "تم الاستلام",
        9: "تم إلغاء الطلب",
        4: "تم إرجاع الطلب"
    };
    const user = localStorage.getItem("user")
    const restaurantId = JSON.parse(user).id;
    const restaurantName = JSON.parse(user).commercialName;
    useEffect(() => {
        const fetchRestaurantWallet = async () => {
            try {
                const response = await axios.get(`https://inifintusdev.tech/api/get_wallet/${restaurantId}`);
                console.log("Drivers total:", response.data);
                setWallet(response.data);
            } catch (error) {
                console.error("Error fetching drivers total:", error);
            }
        };

        fetchRestaurantWallet();
    }, []);

    const handleStatusChange = async (taskId, status) => {
        let task = tasks.find((task) => task.id === taskId);
        console.log(`Task ${task}`);
        try {
            await axios.put(`https://inifintusdev.tech/api/edit_task/${task.id}`, {
                status: status,
            });

            setTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === taskId ? {...task, status: status} : task
                )
            )
        } catch (error) {
            console.error("Error updating task status:", error);

        }
    };


    useEffect(() => {
        // Function to fetch tasks from the API
        const fetchTasks = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get_tasks_admin/${restaurantId}`);
                const newTasks = response.data;
                // Compare new tasks with previous tasks to detect status changes
                newTasks.forEach((newTask) => {
                    const previousTask = previousTasks.find(
                        (task) => task.id === newTask.id
                    );
                    if (previousTask && previousTask.status !== newTask.status) {
                        if (newTask.status == 3) {
                            toast.info(`تم استلام الطلب`, {
                                className: 'toast-custom',
                            });
                        } else if (newTask.status == 2) {
                            toast.info(`تم استلام ddd`, {
                                className: 'toast-custom',
                            });
                        } else if (newTask.status == 9) {
                            toast.info(`تم استلام asd`, {
                                className: 'toast-custom',
                            });
                        }
                    }
                });
                // sort tasks by status
                newTasks.sort((a, b) => {
                    if (a.status < b.status) {
                        return -1;
                    }
                    if (a.status > b.status) {
                        return 1;
                    }
                    return 0;
                });
                setTasks(newTasks);
                setPreviousTasks(newTasks);
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        };

        // Fetch tasks initially and set up an interval to fetch tasks periodically
        const intervalId = setInterval(fetchTasks, 1500); // Adjust the interval to 30 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [previousTasks]);

    const handlePayWithWallet = async (wallet, price,id,driver_id) => {
        let walletId = wallet.id;
        let credits = wallet.credits - price;
        try {
            await axios.put(`https://inifintusdev.tech/api/update_wallet/${walletId}`, {
                credits: credits,
                task_id : id,
                driverId : driver_id
            });
        } catch (error) {
            console.error("Error updating task status:", error);
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        navigate("/taskform");
    };

    return (
        <div>

            <div className="container">
                <div className="content">
                    <div className="nav">
                        <TopNavBar/>
                        <div className="spacer"></div>
                        {/*restaurant name*/}
                        <text style={{width: 1000, fontWeight: 700, fontSize: 24}}>{restaurantName}</text>
                        <div className="spacer"></div>
                        <Button
                            sx={{
                                width: "15vw",
                                fontSize: "18px",
                                mr: "5px",
                            }}
                            variant="contained"
                            color="success"
                            onClick={handleSubmit}
                        >
                            طلب كابتن
                        </Button>
                    </div>
                    <div className="main-content">
                        <div className="taskPanel">
                            {tasks.length === 0 ? (
                                <p>Loading tasks...</p>
                            ) : (
                                tasks.map((task) => (
                                    <div
                                        key={task.id}
                                        className="taskCard"
                                        style={{
                                            backgroundColor: (() => {
                                                switch (task.status) {
                                                    case "0":
                                                        return "cyan";
                                                    case "1":
                                                        return "yellow";
                                                    case "2":
                                                        return "orange";
                                                    case "3":
                                                        return "green";
                                                    case "9":
                                                        return "red";
                                                    case "4":
                                                        return "grey";
                                                    default:
                                                        return "transparent"; // Default background color
                                                }
                                            })(),
                                        }}
                                    >
                                        <div className="task-content-border">
                                            <div><p>العنوان</p> <span> {task.delivery_address}</span></div>
                                            <div><p>السعر</p> <span> {task.price}</span></div>
                                            <div><p>الحالة</p> <span> {statusListInArabic[task.status]}</span></div>
                                            <div><p>السائق</p> <span> {task.driver_name}</span></div>
                                        </div>
                                        <div className="task-button">
                                            <Box sx={{display: 'flex', gap: 2}}>
                                                {task.status === "0" && (
                                                    <Button
                                                        sx={{height: 45}}
                                                        variant="contained"
                                                        color="success"
                                                        onClick={() => handleStatusChange(task.id, "1")}
                                                    >
                                                        جاهز للتوصيل
                                                    </Button>
                                                )}

                                                {["0", "1", "2"].includes(task.status) && (
                                                    <Button
                                                        sx={{height: 45}}
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => handleStatusChange(task.id, "9")}
                                                    >
                                                        إلغاء الطلب
                                                    </Button>
                                                )}

                                                {wallet != null && !task.is_paid && wallet.credits >= task.price && (
                                                    <Button
                                                        sx={{height: 45}}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handlePayWithWallet(wallet, task.price, task.id,task.driver_id)}
                                                    >
                                                        الدفع بالمحفظة
                                                    </Button>
                                                )}
                                            </Box>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="map">
                            <Map isAdmin={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
