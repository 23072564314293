import React, { useState } from "react";
import axios from "axios";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import logo from "../../Assets/Help Me444.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal } from "@mui/joy";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";

const validationSchema = Yup.object({
    username: Yup.string()
        .required('Required')
        .min(3, 'Must be at least 3 characters')
        .max(15, 'Must be 15 characters or less')
        .matches(/^\S*$/, 'No spaces allowed'),
    password: Yup.string()
        .required('Required')
        .min(4, 'Must be at least 4 characters')
        .max(20, 'Must be 20 characters or less'),
    phoneNumber: Yup.string().required('Required')
        .matches(/^[0-9]+$/, "Must be only digits"),
    email: Yup.string().required('Required')
        .email('Invalid email address'),
});

const AddDriver = () => {
    const baseUrl = "https://inifintusdev.tech/api"; // Replace with your actual base URL
    const navigate = useNavigate();


    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            phoneNumber: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(`${baseUrl}/create_driver`, values)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("Driver added successfully");
                        navigate(-1);
                    } else {
                        console.log("Error adding driver");
                    }
                })
                .catch((error) => {
                    console.error("Error adding driver:", error);
                });
        },
    });

    return (
        <div>
            <img
                src={logo}
                style={{
                    position: "absolute",
                    display: "flex",
                    top: -70,
                    left: 30,
                    width: "12%",
                    height: "40%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
                alt="Italian Trulli"
            />
            <div style={{ marginTop: 120 }} className="container">
                <div className="content">
                    <div className="nav">
                        <Container
                            maxWidth="sm"
                            sx={{
                                mt: 5,
                                border: "1px solid black",
                                borderRadius: "15px",
                                padding: "20px",
                            }}
                        >
                            <Typography variant="h4" gutterBottom>
                                تعريف سائق جديد
                            </Typography>
                            <Button
                                sx={{ mb: "10px" }}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                عودة
                            </Button>

                            <form onSubmit={formik.handleSubmit}>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        fullWidth
                                        label="البريد الإلكتروني"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.email && formik.errors.email}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        id="username"
                                        name="username"
                                        fullWidth
                                        label="اسم المستخدم"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.username && formik.errors.username}
                                        error={formik.touched.username && Boolean(formik.errors.username)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        fullWidth
                                        type="password"
                                        label="كلمة السر"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.password && formik.errors.password}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        fullWidth
                                        label="رقم الهاتف"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        variant="outlined"
                                    />
                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                >
                                    انشئ سائق
                                </Button>
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDriver;