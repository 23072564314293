import React from 'react';
import './TopNavBar.scss';
import { Settings, Dashboard, Notifications, Home, CameraAlt, Check, Share, Build } from '@mui/icons-material';
import logo from "../../../Assets/Help Me444.png";
//
const TopNavBar = () => {
    return (
        <div className="top-nav-bar">
            <div className="logo">
                <img
                    src={logo}
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                    alt="Italian Trulli"
                />
            </div>
            {/* <div className="nav-icons">
                <Settings className="nav-icon" />
                <Dashboard className="nav-icon" />
                <Notifications className="nav-icon" />
                <Home className="nav-icon" />
                <CameraAlt className="nav-icon" />
                <Check className="nav-icon" />
                <Build className="nav-icon" />
                <Share className="nav-icon" />
            </div> */}
        </div>
    );
};

export default TopNavBar;
