import React, { useState, useContext, useEffect } from 'react';
import './Login.scss';
import { Card, CardContent, Typography, TextField, Button, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../UserContext';
import logo from '../../Assets/Help Me444.png';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const { login } = useContext(UserContext);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://inifintusdev.tech/api/get_restaurants');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const user = users.find(u => u.username === username && u.password === password);
    if (user) {
      login(user);
          // const user = localStorage.getItem("user")
        localStorage.setItem("user",JSON.stringify(user))
      navigate('/settings');
    } else {
      setErrorMessage('Incorrect username or password');
    }
  };

  return (
    <div>
      <img
        src={logo}
        style={{
          position: 'absolute',
          display: 'flex',
          top: -70,
          left: 30,
          width: '12%',
          height: '40%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        alt='Logo'
      />
      <Card sx={{ borderRadius: '35px', backdropFilter: 'blur', backgroundColor: 'rgba(0,0,30,0.4)' }}
        className='card'>
        <CardContent>
          <Typography className='formLabel' variant='h5' component='div' gutterBottom>
            Login
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label='Username'
              type='text'
              variant='outlined'
              margin='normal'
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              fullWidth
              label='Password'
              type='password'
              variant='outlined'
              margin='normal'
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && (
              <Typography color='error' variant='body2' component='p'>
                {errorMessage}
              </Typography>
            )}
            <CardActions>
              <Button type='submit' size='large' variant='contained' fullWidth>
                Submit
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
