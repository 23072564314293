import React, { useEffect, useState } from "react";
import {Container, Box, Button, Typography, useMediaQuery, TableCell} from "@mui/material";
import Map from "../Settings/map/Map"; // Import the Map component
import defaultImage from "../../Assets/default.png";
import { Card, CardContent, CardMedia } from "@mui/material";
import { Modal } from '@mui/material';
import './admin.css'
import axios from "axios";
import { Divider } from '@mui/material';

const AdminMap = () => {
    const [drivers, setDrivers] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [driverName, setDriverName] = useState('');

    const fetchDriverTasks = () => {
        try {
            axios.get('https://inifintusdev.tech/api/get_tasks_admin')
                .then((res) => {
                    setTasks(res.data);
                    console.log(tasks, res.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        } catch (error) {
            console.log(error);
        }
    };
// If you need to perform an action after the state update:
    useEffect(() => {
        console.log("Updated tasks state:", tasks);
    }, [tasks]); // This effect will run every time `tasks` is updated
    const handleOpen = (test) => {
        setOpen(true);
    //     filter tasks based on the driver id
        const driverTasks = tasks.filter((task) => task?.Driver?.id === test).filter((task) => task.status !== '9');
        setFilteredTasks(driverTasks);
    };

    const handleClose = () => setOpen(false);

    const fetchDriverList = () => {
        fetch("https://inifintusdev.tech/api/all_drivers")
            .then((res) => res.json())
            .then((data) => {
                setDrivers(data);
            });
    };

    useEffect(() => {
        fetchDriverList();
        fetchDriverTasks();
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isSmallScreen ? '90%' : 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Container
            sx={{
                height: "auto",
                width: isSmallScreen ? '90vw' : '70vw',
                marginLeft: isSmallScreen ? 0 : 30,
                marginTop: 2,
                border: "solid",
                borderRadius: "25px",
                padding: 2,
            }}
        >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {driverName} Tasks
                    </Typography>
                    <Box id="modal-modal-description" sx={{ mt: 2 }}>
                        {filteredTasks.length > 0 ? (
                            filteredTasks.map((task, index) => (
                                <React.Fragment key={task.id}>
                                    <Box sx={{ mb: 2 }}>
                                        <Typography variant="body1">
                                            <strong>Delivery Address:</strong> {task.delivery_address}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Restaurant:</strong> {task.Restaurant.commercialName}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Price:</strong> {task.price}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Created At:</strong> {task.createdAt}
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong>Status:</strong> {task.status}
                                        </Typography>
                                    </Box>
                                    {index < filteredTasks.length - 1 && <Divider />}
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="body1">No tasks available.</Typography>
                        )}
                    </Box>
                </Box>
            </Modal>

            <Box sx={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                top: isSmallScreen ? 0 : -20,
                left: isSmallScreen ? 0 : 30,
            }}>
                <Box sx={{ width: '100%', marginTop: isSmallScreen ? '100px' : '200px' }}>
                    {/* driver list scrollable */}
                    <Box
                        sx={{
                            height: "60vh",
                            width: "100%",
                            padding: 2,
                            overflow: "auto",
                        }}
                    >
                        <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
                            قائمة السائقين
                        </Typography>
                        {drivers.map((driver) => (
                            <Card
                                key={driver.id}
                                sx={{
                                    padding: 2,
                                    mb: 1,
                                    width: isSmallScreen ? '50%' : 200,
                                    display: 'flex',
                                    flexDirection: isSmallScreen ? 'column' : 'row',
                                    alignItems: 'center',
                                    backgroundColor: '#f0f0f0', // Light grey background
                                    border: '1px solid #ccc', // Light grey border
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
                                    borderRadius: '10px', // Rounded corners
                                    position: 'relative', // To position the is_active indicator
                                }}
                                onClick={() => {
                                    handleOpen(driver.id);
                                    setDriverName(driver.username);
                                }}
                            >
                                {/* is_active Indicator */}
                                <Box
                                    sx={{
                                        width: 25,
                                        height: '150%',
                                        backgroundColor: driver.is_active ? '#28a745' : '#dc3545', // Green for active, Red for inactive
                                        position: 'absolute',
                                        top: -5,
                                        right: -5,
                                        border: '2px solid #fff', // Optional: white border around the circle
                                    }}
                                />
                                <CardMedia
                                    component="img"
                                    image={driver.image ? driver.image : defaultImage}
                                    alt="Driver"
                                    sx={{
                                        width: isSmallScreen ? "100%" : "75px",
                                        height: isSmallScreen ? "auto" : "75px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                        mb: 1,
                                        border: '2px solid #007bff', // Blue border around the image
                                    }}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            textAlign: 'center',
                                            color: '#007bff', // Blue text color
                                            width: isSmallScreen ? '100%' : 100,
                                        }}
                                    >
                                        {driver.username}
                                    </Typography>
                                    {driver.device?.name && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                textAlign: 'center',
                                                color: '#555', // Dark grey text color
                                            }}
                                        >
                                            {driver.device.name}
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box display="flex" mb={3} mt={2} alignItems="center">
                <Typography variant="h4" sx={{ flexGrow: 1, p: 1 }}>
                    صفحة الإدارة
                </Typography>
            </Box>

            <Box  sx={{ width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                    <Map isAdmin={true} />
                </Box>
            </Box>
        </Container>
    );
};

export default AdminMap;