import React, {useEffect, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Typography,
    Box,
    TableSortLabel,
    TextField, Button, MenuItem, FormControl, Select,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios";

const DriversTable = ({
                          drivers,
                          driverPage,
                          driverRowsPerPage,
                          handleDriverChangePage,
                          handleDriverChangeRowsPerPage,
                          setReRender,
                          reRender
                      }) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [searchQuery, setSearchQuery] = useState("");
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openAssign, setOpenAssign] = React.useState(false);
    const [driver, setDriver] = useState({});
    const [devices, setDevices] = useState([]);
    const [driverId, setDriverId] = useState(0);
    const [deviceId, setDeviceId] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredDrivers = drivers.filter((driver) =>
        driver.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        driver.phoneNumber.includes(searchQuery)
    );

    const sortedDrivers = filteredDrivers.sort((a, b) => {
        if (order === "asc") {
            return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
        } else {
            return a[orderBy] > b[orderBy] ? -1 : a[orderBy] < b[orderBy] ? 1 : 0;
        }
    });


    const handleClickOpen = () => {
        setOpenEdit(true);
    };
    const handleClose = () => {
        setOpenEdit(false);
        //     reload the tasks
        window.location.reload();
    };
    const editDriver = (id) => {
        //     open FormDialog
        setDriver(drivers.find(driver => driver.id === id));
        handleClickOpen();
    }

    const assignDriver = (driverId, deviceId) => {
        if (deviceId === null) {
            deviceId = "";
        }
        setDeviceId(deviceId);
        setDriverId(driverId);
        setOpenAssign(true);
    }
    const handleCloseAssign = () => {
        setOpenAssign(false)
    }

    useEffect(() => {
        axios.get("https://inifintusdev.tech/api/devices").then((res) => {
            setDevices(res.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [reRender])

    return (
        <Box>
            <Typography variant="h4" align="center" gutterBottom>
                قائمة السائقين
            </Typography>
            <TextField
                fullWidth
                label="ابحث"
                id="search"
                value={searchQuery}
                onChange={handleSearchChange}
                margin="normal"
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "username"}
                                    direction={orderBy === "username" ? order : "asc"}
                                    onClick={() => handleRequestSort("username")}
                                >
                                    اسم السائق
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "phoneNumber"}
                                    direction={orderBy === "phoneNumber" ? order : "asc"}
                                    onClick={() => handleRequestSort("phoneNumber")}
                                >
                                    رقم الهاتف
                                </TableSortLabel>
                            </TableCell>

                            {/*status if online or not*/}
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "is_active"}
                                    direction={orderBy === "is_active" ? order : "asc"}
                                    onClick={() => handleRequestSort("is_active")}
                                >
                                    الحالة
                                </TableSortLabel>
                            </TableCell>
                            {/*cell to show device name*/}
                            <TableCell

                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    border: "1px solid black",
                                }}
                            >
                                {/*<TableSortLabel*/}
                                {/*    active={orderBy === "device"}*/}
                                {/*    direction={orderBy === "device" ? order : "asc"}*/}
                                {/*    onClick={() => handleRequestSort("device")}*/}
                                {/*>*/}
                                السيارة
                                {/*</TableSortLabel>*/}

                            </TableCell>
                            {/*    new cell to edit driver username and password*/}
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    width: "10%",
                                    border: "1px solid black",
                                }}
                            >
                                تعديل
                            </TableCell>
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    width: "10%",
                                    border: "1px solid black",
                                }}
                            >
                                تعيين
                            </TableCell>
                            {/*    new cell to finish the driver session*/}
                            <TableCell
                                sx={{
                                    color: "black",
                                    fontWeight: "bold",
                                    width: "15%",
                                    border: "1px solid black",
                                }}
                            >
                                إنهاء الجلسة
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedDrivers
                            .slice(
                                driverPage * driverRowsPerPage,
                                driverPage * driverRowsPerPage + driverRowsPerPage
                            )
                            .map((driver, index) => (
                                <TableRow
                                    key={driver.id}
                                    sx={{
                                        backgroundColor: index % 2 === 0 ? "lightgray" : "white",
                                        border: "1px solid black",
                                    }}
                                >
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {driver.username}
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {driver.phoneNumber}
                                    </TableCell>

                                    <TableCell sx={{border: "1px solid black"}}>
                                        {/*    if driver.status === true show green box and online else red and offline*/}
                                        {driver.is_active ? (
                                            <Box
                                                sx={{
                                                    width: "10px",
                                                    height: "10px",
                                                    backgroundColor: "green",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: "10px",
                                                    height: "10px",
                                                    backgroundColor: "red",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        )

                                        }
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        {driver?.device?.name}
                                    </TableCell>
                                    {/*    new cell to edit driver username and password*/}
                                    <TableCell sx={{border: "1px solid black"}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => editDriver(driver.id)}
                                        >
                                            تعديل
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{border: "1px solid black"}}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => assignDriver(driver.id, driver?.Device?.id)}
                                        >
                                            تعيين
                                        </Button>
                                    </TableCell>
                                    {/*   finish the driver  session*/}
                                    <TableCell sx={{border: "1px solid black"}}>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => {
                                                if (driver.device === null) {
                                                    alert("السائق ليس لديه جهاز");
                                                    return;
                                                }
                                                axios.put(`https://inifintusdev.tech/api/finish_driver_session/${driver.id}/${driver.device.id}`).then((res) => {
                                                    console.log(res.data);
                                                    setReRender(!reRender);
                                                }).catch((err) => {
                                                    console.log(err);
                                                })
                                            }
                                            }
                                        >
                                            إنهاء الجلسة
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 25, 100, 500]}
                    component="div"
                    count={filteredDrivers.length}
                    rowsPerPage={driverRowsPerPage}
                    page={driverPage}
                    onPageChange={handleDriverChangePage}
                    onRowsPerPageChange={handleDriverChangeRowsPerPage}
                />
            </TableContainer>
            <Dialog
                open={openAssign}
                onClose={handleCloseAssign}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        axios.put(`https://inifintusdev.tech/api/assign_device_to_driver/${driverId}/${deviceId}`).then((res) => {
                            console.log(res.data);
                            handleCloseAssign();
                            setReRender(!reRender);
                        }).catch((err) => {
                            console.log(err);
                            handleCloseAssign();
                        })
                    }
                }}>
                <DialogTitle>تعيين السائق</DialogTitle>
                <DialogContent>
                    <FormControl sx={{mt: 2, minWidth: 250}}>
                        <Select
                            autoFocus
                            label="الجهاز"
                            value={deviceId}
                            onChange={(event) => {
                                setDeviceId(event.target.value);
                            }}
                            inputProps={
                                {
                                    name: 'device',
                                    id: 'device',
                                }
                            }
                        >
                            {devices.map((device) => (
                                <MenuItem key={device.id} value={device.id}>
                                    {device.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAssign}>إلغاء</Button>
                    <Button type="submit">تعيين</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openEdit}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        let email = formJson.email;
                        let username = formJson.username;
                        let password = formJson.password;
                        // if value is empty, keep the old value
                        if (email === "") {
                            email = driver.email;
                        }
                        if (username === "") {
                            username = driver.username;
                        }
                        if (password === "") {
                            password = driver.password;
                        }
                        axios.put(`https://help-me-app-restaurant-backend.vercel.app/edit_driver/${driver.id}`, {
                            email: email,
                            username: username,
                            password: password
                        }).then((res) => {
                            console.log(res.data);
                            handleClose();
                            setReRender(!reRender);
                        }).catch((err) => {
                            console.log(err);
                            handleClose();
                        })

                    },
                }}
            >
                <DialogTitle>تعديل السائق</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        name="email"
                        label="عنوان البريد الإلكتروني"
                        type="email"
                        fullWidth
                        variant="standard"
                        placeholder={driver.email}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        name="username"
                        label="اسم المستخدم"
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder={driver.username}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        name="password"
                        label="كلمة المرور"
                        type="text"
                        fullWidth
                        variant="standard"
                        placeholder={driver.password}
                    />
                {/*    edit photo*/}
                {/*    <Box>*/}
                {/*        <Button*/}
                {/*            variant="contained"*/}
                {/*            component="label"*/}
                {/*            color="secondary"*/}
                {/*            fullWidth*/}
                {/*        >*/}
                {/*            Upload Photo*/}
                {/*            <input*/}
                {/*                type="file"*/}
                {/*                hidden*/}
                {/*                onChange={handleFileChange}*/}
                {/*            />*/}
                {/*        </Button>*/}
                {/*        {selectedFile && (*/}
                {/*            <Typography variant="body2" sx={{ mt: 1 }}>*/}
                {/*                Selected file: {selectedFile.name}*/}
                {/*            </Typography>*/}
                {/*        )}*/}
                {/*    </Box>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>إلغاء</Button>
                    <Button type="submit">تعديل</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DriversTable;
