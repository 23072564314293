import React, {useState, useEffect, useContext} from "react";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Container,
    TextField,
    Button,
    Grid,
    FormLabel,
} from "@mui/material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import logo from "../../Assets/Help Me444.png";
import {AdminContext} from "../../AdminContext";

const API_BASE_URL = "https://inifintusdev.tech/api";

const AddWallet = () => {
    const {admin} = useContext(AdminContext);
    const [restaurants, setRestaurants] = useState([]);
    const [form, setForm] = useState({
        credits: 0,
        restaurantId: 1,
    });

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure that form.credits and form.restaurantId have values
        const {credits, restaurantId} = form;
        if (!credits || !restaurantId) {
            alert("Please provide both credits and restaurant ID.");
            return;
        }

        const updatedForm = {
            credits: parseFloat(credits), // Ensure credits is a number
            restaurantId: parseInt(restaurantId), // Ensure restaurantId is an integer
        };

        try {
            const response = await axios.post(
                `${API_BASE_URL}/create_wallet`,
                updatedForm,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            console.log("Credits added successfully:", response.data);
            alert("تم اضافة رصيد");
            navigate("/admin/homepage");
        } catch (error) {
            console.error("Error submitting form:", error);

            // You can also display a user-friendly error message
            alert("Failed to add credits. Please try again.");
        }
    };

    useEffect(() => {
        axios
            .get(`${API_BASE_URL}/get_restaurants`)
            .then((response) => {
                if (response.data) {
                    setRestaurants(response.data);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleRestaurantChange = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            restaurantId: e.target.value,
        }));
    };

    if (!admin) {
        navigate("/admin");
        return null;
    }

    return (
        <div>

            <div style={{marginTop: 120}} className="container">
                <div style={{marginLeft: "530px"}}>
                    <div className="nav">
                        <Container
                            maxWidth="md"
                            sx={{
                                bgcolor: "background.default",
                                color: "text.primary",
                                padding: 3,
                                borderRadius: 2,
                                border: "solid black 1px",
                                mt: 8,
                            }}
                        >
                            <FormLabel>صفحة انشاء محفظه</FormLabel>

                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="الرصيد"
                                        name="credits"
                                        value={form.credits}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        sx={{bgcolor: "background.paper", width: "100%"}}
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12} sm={6}>
                                        <FormControl margin="normal">
                                            <InputLabel id="restaurant-label">مطاعم</InputLabel>
                                            <Select
                                                labelId="restaurant-label"
                                                id="restaurantId"
                                                name="restaurantId"
                                                value={form.restaurantId}
                                                onChange={handleRestaurantChange}
                                                variant="outlined"
                                                sx={{width: "100%"}}
                                                fullWidth
                                            >
                                                <MenuItem value="">
                                                    <em>اختر مطعم</em>
                                                </MenuItem>
                                                {restaurants.map((restaurant) => (
                                                    <MenuItem key={restaurant.id} value={restaurant.id}>
                                                        {restaurant.commercialName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            sx={{height: "56px", width: "100%"}}
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                        >
                                            انشئ محفظه
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddWallet;
