import {
    Button,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Total() {
    const [drivers, setDrivers] = useState([]);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const total = async () => {
            try {
                const response = await axios.get("https://inifintusdev.tech/api/all_drivers_total");
                setDrivers(response.data);
            } catch (error) {
                console.error("Error fetching drivers:", error);
            }
        };
        total();
    }, [loader]);

    const driverId = async (driver_id) => {
        try {
            const response = await axios.put(`https://inifintusdev.tech/api/change_money_from_driver/${driver_id}`);
            console.log(response.data);
        } catch (error) {
            console.error("Error updating driver:", error);
        }
        setLoader(!loader);
    };

    // Filter drivers based on search query
    const filteredDrivers = drivers.filter((driver) =>
        (driver.driver_name && driver.driver_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (driver.wallet_money && driver.wallet_money.toString().includes(searchQuery)) ||
        (driver.total_amount && driver.total_amount.toString().includes(searchQuery))
    );

    return (
        <div>
            <Typography sx={{ mb: 2 }}>
                <Typography sx={{ ml: 30 }} variant='h2'>التحصيلات</Typography>
            </Typography>
            <TextField
                fullWidth
                label="ابحث"
                id="search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)} // Update search query
                margin="normal"
                sx={{ mb: 2 }} // Margin for spacing
            />
            <Card gutterBottom sx={{ borderRadius: 5, width: '100vh' }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                        border: "1px solid black",
                                        backgroundColor: "lightgray",
                                    }}
                                >
                                    اسم السائق
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                        border: "1px solid black",
                                        backgroundColor: "lightgray",
                                    }}
                                >
                                    مجموع مبلغ التوصيل
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                        border: "1px solid black",
                                        backgroundColor: "lightgray",
                                    }}
                                >
                                    مجموع المبلغ مع السائق
                                </TableCell>
                                <TableCell
                                    sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                        border: "1px solid black",
                                        backgroundColor: "lightgray",
                                    }}
                                >
                                    تغير الحاله
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredDrivers.length === 0 ? (
                                <Typography>Loading Prices...</Typography>
                            ) : (
                                filteredDrivers.map((driver, index) => (
                                    <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'white' : 'gray' }}>
                                        <TableCell sx={{ border: "1px solid black" }}>
                                            {driver.driver_name || 'N/A'}
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid black" }}>
                                            {driver.wallet_money || '0'}
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid black" }}>
                                            {driver.total_amount || '0'}
                                        </TableCell>
                                        <TableCell sx={{ border: "1px solid black" }}>
                                            <Button
                                                sx={{ mr: 5 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => driverId(driver.id)}
                                            >
                                                استلام
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </div>
    );
}

export default Total;
