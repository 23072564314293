import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";//   {

const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const AddressNav = ({ onAddressSelect }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
      axios.get("https://inifintusdev.tech/api/get_regions")
          .then((response) => {
              if (response.data) {
                  setAddressOptions(response.data);
                  setFilteredOptions(response.data);
              }
          })
          .catch((error) => console.log(error));
  },[])

  useEffect(() => {

    const results = addressOptions.filter((option) =>
      option.value.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredOptions(results);
  }, [searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const optionChunks = chunkArray(filteredOptions, 8);
  return (
    <div>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "20px",
          fontWeight: "bold",
          fontStyle: "italic",
        }}
      >
        {" "}
        عنوانين التسليم
      </Typography>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "white",
          padding: "10px 0",
        }}
      >
        <TextField
          label="Search Address"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          variant="outlined"
          margin="normal"
          sx={{ marginBottom: "20px" }}
        />
      </div>
      <Grid container spacing={2}>
        {optionChunks.map((chunk, chunkIndex) => (
          <Grid item xs={12} key={`chunk-${chunkIndex}`}>
            <Grid container spacing={2}>
              {chunk.map((option) => (
                <Grid item xs={6} sm={4} md={3} key={option.key}>
                  <Button
                    sx={{
                      color: "black",
                      padding: "2px",
                      backgroundColor: "white",
                      width: "100%",
                      height: "8vh",
                      mt: "1px",
                      border: "solid 1px black",
                    }}
                    onClick={() => onAddressSelect(option)}
                  >
                    <ListItemText primary={option.value} />
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default AddressNav;
