import React from 'react';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {Container, Typography, Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DriversTable from "./DriversTable";
import logo from "../../Assets/Help Me444.png";
import RestaurantsTable from "./RestaurantsTable";

const Driver = () => {
    const [drivers, setDrivers] = useState([]);
    const [driverPage, setDriverPage] = useState(0);
    const [driverRowsPerPage, setDriverRowsPerPage] = useState(25);
    const [reRender, setReRender] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        axios.get('https://inifintusdev.tech/api/all_drivers')
            .then((res) => {
                setDrivers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reRender]);

    const handleDriverChangePage = (event, newPage) => {
        setDriverPage(newPage);
    };
    const handleDriverChangeRowsPerPage = (event) => {
        setDriverRowsPerPage(parseInt(event.target.value, 10));
        setDriverPage(0);
    }
    const taskPageButton = () => {
        navigate("/admin/homepage");
    };

    return (
        <Container
            sx={{
                height: "auto",
                width: "80vw",
                mt: 20,
                border: "solid",
                borderRadius: "25px",
                padding: 2,
            }}
        >
            
            <Box display="flex" mb={3} mt={2} alignItems="center">
                <Typography variant="h4" sx={{flexGrow: 1, p: 1}}>
                    صفحة السائقين
                </Typography>
                {/* <Button
                    sx={{mr: 2}}
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("/admin/add-device")}
                >
                    اضافة سيارة
                </Button>
                <Button
                    sx={{mr: 2}}
                    variant="contained"
                    color="success"
                    onClick={() => navigate("/admin/add-driver")}
                >
                    إضافة سائق
                </Button> */}
                {/* <Button
                    sx={{mr: 2}}
                    variant="contained"
                    color="primary"
                    onClick={taskPageButton}
                >
                    قائمة المهام
                </Button> */}


            </Box>
            <DriversTable
                drivers={drivers}
                driverPage={driverPage}
                driverRowsPerPage={driverRowsPerPage}
                handleDriverChangePage={handleDriverChangePage}
                handleDriverChangeRowsPerPage={handleDriverChangeRowsPerPage}
                setReRender={setReRender}
                reRender={reRender}
            />
        </Container>
    );
};

export default Driver;
