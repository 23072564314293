import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Box,
  TableSortLabel,
  TextField,
} from "@mui/material";

const RestaurantsTable = ({
  restaurants,
  restaurantPage,
  restaurantRowsPerPage,
  handleRestaurantChangePage,
  handleRestaurantChangeRowsPerPage,
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("commercialName");
  const [searchQuery, setSearchQuery] = useState("");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRestaurants = restaurants.filter(
    (restaurant) =>
      restaurant.commercialName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      restaurant.phoneNumber.includes(searchQuery)
  );

  const sortedRestaurants = filteredRestaurants.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : a[orderBy] > b[orderBy] ? 1 : 0;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : a[orderBy] < b[orderBy] ? 1 : 0;
    }
  });

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        قائمة المطاعم
      </Typography>
      <TextField
        fullWidth
        label="ابحث"
        id="search"
        value={searchQuery}
        onChange={handleSearchChange}
        margin="normal"
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid black",
                }}
              >
                <TableSortLabel
                  active={orderBy === "commercialName"}
                  direction={orderBy === "commercialName" ? order : "asc"}
                  onClick={() => handleRequestSort("commercialName")}
                >
                  اسم التجاري
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid black",
                }}
              >
                <TableSortLabel
                  active={orderBy === "phoneNumber"}
                  direction={orderBy === "phoneNumber" ? order : "asc"}
                  onClick={() => handleRequestSort("phoneNumber")}
                >
                  رقم الهاتف
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid black",
                }}
              >
                <TableSortLabel
                  active={orderBy === "credits"}
                  direction={orderBy === "credits" ? order : "asc"}
                  onClick={() => handleRequestSort("credits")}
                >
                  الرصيد
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRestaurants
              .slice(
                restaurantPage * restaurantRowsPerPage,
                restaurantPage * restaurantRowsPerPage + restaurantRowsPerPage
              )
              .map((restaurant, index) => (
                <TableRow
                  key={restaurant.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "lightgray" : "white",
                    border: "1px solid black",
                  }}
                >
                  <TableCell sx={{ border: "1px solid black" }}>
                    {restaurant.commercialName}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid black" }}>
                    {restaurant.phoneNumber}
                  </TableCell>
                  <TableCell sx={{ border: "1px solid black" }}>
                    {restaurant?.Wallet?.credits}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 100, 500]}
          component="div"
          count={filteredRestaurants.length}
          rowsPerPage={restaurantRowsPerPage}
          page={restaurantPage}
          onPageChange={handleRestaurantChangePage}
          onRowsPerPageChange={handleRestaurantChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default RestaurantsTable;
