import React, { useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CardActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AdminContext } from '../../AdminContext'; // Import AdminContext
import logo from "../../Assets/Help Me444.png";

function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AdminContext); // Use AdminContext

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        "https://inifintusdev.tech/api/admin_login",
        {
          username: username,
          password: password,
        }
      );
      if (response.status === 200) {
        // Login successful
        login(response.data); // Save admin data to context
        navigate("/admin/homepage");
      } else if (response.status === 401) {
        setErrorMessage("Invalid username or password");
      } else {
        setErrorMessage("Internal server error");
      }
    } catch (error) {
      console.error("Error during admin login:", error);
      setErrorMessage("Error during admin login");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <div>
       
      <Card
        sx={{
          borderRadius: "35px",
          backdropFilter: "blur",
          backgroundColor: "rgba(0,0,30,0.4)",
        }}
        className="card"
      >
        <CardContent>
          <Typography
            className="formLabel"
            variant="h5"
            component="div"
            gutterBottom
          >
            تسجيل الدخول
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="اسم المستخدم"
              type="text"
              variant="outlined"
              margin="normal"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              fullWidth
              label="كلمة السر"
              type="password"
              variant="outlined"
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && (
              <Typography color="error" variant="body2" component="p">
                {errorMessage}
              </Typography>
            )}
            <CardActions>
              <Button
                type="submit"
                size="large"
                variant="contained"
                fullWidth
              >
                تسجيل الدخول
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default AdminLogin;
