import React, {useState, useEffect} from "react";
import {
    Container,
    TextField,
    Button,
    Grid,
    Dialog,
    DialogContent,
    CircularProgress,
    Box
} from "@mui/material";
import axios from "axios";
import {v4 as uuidv4} from "uuid";
import AddressNav from "./AddressNav";
import {useNavigate} from "react-router-dom";

// Constants
const API_BASE_URL = "https://inifintusdev.tech/api";

// Utility functions
const generateUniqueId = () => `INV-${uuidv4()}`;

const fetchClosestDriver = async () => {
    let shopAddress = JSON.parse(localStorage.getItem("user")).address;
    shopAddress = shopAddress.split(",");
    shopAddress = {lat: shopAddress[0], lng: shopAddress[1]};
    if (!shopAddress || !shopAddress.lat || !shopAddress.lng) {
        console.error("Invalid shop address");
        return null;
    }
    try {
        const response = await axios.get(`${API_BASE_URL}/closest-driver`, {
            params: {lat: shopAddress.lat, lng: shopAddress.lng},
        });
        return response.data.driver.id;
    } catch (error) {
        console.error("Error fetching closest driver:", error);
        return null;
    }
};

const TaskForm = () => {
    const restaurantAddress = JSON.parse(localStorage.getItem("user")).address;
    const restaurantName = JSON.parse(localStorage.getItem("user")).commercialName;
    const restaurantAddressLat = restaurantAddress.split(",")[0];
    const restaurantAddressLng = restaurantAddress.split(",")[1];
    const restaurantId = JSON.parse(localStorage.getItem("user")).id;
    const [regions, setRegions] = useState([]);
    const [device, setDevice] = useState("");
    const [enableButton, setEnableButton] = useState(true);
    const [form, setForm] = useState({
        device_id: null,
        user_id: null,
        title: null,
        comment: null,
        priority: null,
        status: "0",
        invoice_number: generateUniqueId(),
        pickup_address: null,
        pickup_address_lat: null,
        pickup_address_lng: null,
        pickup_time_from: null,
        pickup_time_to: null,
        delivery_address: null,
        delivery_address_lat: null,
        delivery_address_lng: null,
        delivery_time_from: null,
        delivery_time_to: null,
        delivery_region: null,
        client_phone_number: null,
        restaurantId: null,
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm((prevForm) => ({...prevForm, [name]: value}));
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const updatedForm = {
            ...form,
            device_id: parseInt(device),
            pickup_address: String(restaurantName),
            pickup_address_lat: String(restaurantAddressLat),
            pickup_address_lng: String(restaurantAddressLng),
            restaurantId: parseInt(restaurantId),
        };
        try {
            const response = await axios.post(
                `${API_BASE_URL}/add_task_admin`,
                updatedForm,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            console.log("Task added successfully:", response.data);
            setLoading(false);
            navigate("/settings");
        } catch (error) {
            console.error("Error submitting form:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (device && form.delivery_region && form.delivery_address) {
            setEnableButton(false);
        }
    }, [device, form.delivery_region, form.delivery_address]);

    const handleAddressSelect = (selectedAddress) => {
        setForm((prevForm) => ({
            ...prevForm,
            delivery_address: selectedAddress.value,
            delivery_address_lat: selectedAddress.lat || "",
            delivery_address_lng: selectedAddress.lng || "",
            delivery_region: selectedAddress.id || "",
        }));
        handleClose();
    };

    useEffect(() => {
        const fetchData = async () => {
            const closestDriver = await fetchClosestDriver();
            if (closestDriver) {
                setDevice(closestDriver);
            }
        };

        axios
            .get("https://inifintusdev.tech/api/get_regions")
            .then((response) => {
                if (response.data) {
                    setRegions(response.data);
                }
            })
            .catch((error) => console.log(error));

        fetchData();
    }, []);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handelNavigate = () => {
        navigate("/settings");
    };

    return (
        <Container
            maxWidth="sm"
            sx={{bgcolor: "gray", color: "white", padding: 3, borderRadius: 2, mt: 10}}
        >
            <Button
                sx={{
                    display: 'block',
                    mb: '20px',
                    mx: 'auto',
                    textAlign: 'center',
                }}
                variant="contained"
                onClick={handleClickOpen}
            >
                اختار عنوان التسليم
            </Button>
            {form.delivery_address && (
                <p style={{textAlign: "center"}}>
                    {form.delivery_address}
                </p>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        height: "80vh",
                        width: "60vw",
                    },
                }}
            >
                <DialogContent>
                    <AddressNav onAddressSelect={handleAddressSelect}/>
                </DialogContent>
            </Dialog>
            <Button variant="contained" onClick={handelNavigate}>
                الخريطة
            </Button>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="رقم الفاتورة"
                            name="invoice_number"
                            value={form.invoice_number}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            sx={{bgcolor: "white"}}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            label="تفاصيل العنوان"
                            name="title"
                            value={form.title}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            sx={{bgcolor: "white"}}
                        />
                    </Grid>
                    <Grid item xs={20} sm={15}>
                        <TextField
                            label="تفاصيل الطلبية"
                            name="comment"
                            value={form.comment}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            sx={{bgcolor: "white"}}
                        />
                    </Grid>
                    <Grid style={{margin: 20}}>
                        <TextField
                            label="رقم الهاتف للعميل"
                            name="client_phone_number"
                            value={form.client_phone_number}
                            onChange={handleChange}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            sx={{bgcolor: "white"}}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button
                            sx={{height: "10vh", width: "100%"}}
                            variant="contained"
                            color="success"
                            type="submit"
                            disabled={enableButton}
                        >
                            إرسال
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {loading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 1200, // MUI Dialog uses 1200
                    }}
                >
                    <CircularProgress sx={{color: 'white', size: '50px'}}/>
                </Box>
            )}
        </Container>
    );
};

export default TaskForm;
