import React, {useEffect, useState, useRef, useCallback} from "react";
import "leaflet/dist/leaflet.css";
import icon from "../../../shop.png";
import {useLocation} from "react-router-dom";
import carIconImage from "../../../car1.png";
import {socket} from "../../../Socket";


const GoogleMapsLoader = ({isAdmin}) => {
    const mapRef = useRef(null);
    const driverMarkersRef = useRef({});
    const [positions, setPositions] = useState(new Map());
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        fetch("https://inifintusdev.tech/api/all_drivers")
            .then((res) => res.json())
            .then((data) => {
                setDrivers(data);
            });
    }, [])


    useEffect(() => {
        if (!mapRef.current) {
            mapRef.current = L.map("map").setView([31.898043, 35.204269], 12.5);
            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(mapRef.current);
        }
    }, []);
    // fetch restaurants
    const fetchRestaurants = useCallback(() => {
        fetch("https://inifintusdev.tech/api/get_restaurants")
            .then((response) => response.json())
            .then((data) => {
                data.forEach((restaurant) => {
                    let [latitude, longitude] = restaurant.address.split(",");
                    latitude = parseFloat(latitude);
                    if (longitude[0] === " ") {
                        longitude = longitude.slice(1);
                    }
                    longitude = parseFloat(longitude);
                    if (isNaN(latitude) || isNaN(longitude)) {
                        return;
                    }
                    const restaurantIconSrc = restaurant.image ? restaurant.image : icon;
                    // give icon border radius of 50%

                    const restaurantIcon = L.icon({
                        iconUrl: restaurantIconSrc,
                        iconSize: [25, 25],
                        iconAnchor: [16, 16],
                    });
                    L.marker([latitude, longitude], {icon: restaurantIcon})
                        .addTo(mapRef.current)
                        .bindPopup(restaurant.commercialName);

                });
            });
    }, []);

    // fetch restaurants
    useEffect(() => {
        fetchRestaurants();
    }, []);

    // fetch available device id for the restaurant
    const fetchAvailableDeviceId = useCallback(() => {
        fetch(`https://inifintusdev.tech/api/get_devices_with_restaurant/${JSON.parse(localStorage.getItem("user")).id}`)
            .then((response) => response.json())
            .then((data) => {
                data.forEach((deviceId) => {
                    console.log(deviceId);
                });
            });
    }, []);

    // fetch available device id for the restaurant if the user is not an admin
    useEffect(() => {
        if (!isAdmin) {
            fetchAvailableDeviceId();
        }
    }, []);

    useEffect(() => {

        positions.forEach((driver, deviceId) => {
            let iconSize, iconAnchor;
            if (deviceId in drivers) {
                driver.image = drivers[deviceId].image;
                iconSize = [25, 25];
                iconAnchor = [16, 16];
            }
            if (!driver.image) {
                iconSize = [50, 50];
                iconAnchor = [25, 25];
            }
            const carIconImagePath = driver.image ? driver.image : carIconImage;

            const carIcon = L.icon({
                iconUrl: carIconImagePath,
                iconSize: iconSize,
                iconAnchor: iconAnchor,
            });

            if (!driverMarkersRef.current[deviceId]) {
                driverMarkersRef.current[deviceId] = L.marker([driver.lat, driver.lng], {icon: carIcon})
                    .addTo(mapRef.current)
                    .bindPopup(`Driver ${deviceId}`);
            } else {
                driverMarkersRef.current[deviceId].setLatLng([driver.lat, driver.lng]);
            }
        });
    }, [positions]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            socket.on("message", (data) => {
                const {latitude: lat, longitude: lng, deviceId} = data.positions[0];
                setPositions((prev) => {
                    const newPositions = new Map(prev);
                    newPositions.set(deviceId, {lat, lng});
                    return newPositions;
                });
            });
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    return <div id="map" style={{height: "77vh", width: "100%"}}></div>;
};

export default GoogleMapsLoader;
