import React, {useState, useEffect, useContext} from "react";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Container,
    TextField,
    Button,
    Grid,
    FormLabel,
} from "@mui/material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {AdminContext} from "../../AdminContext";
import {Card, Typography} from "@mui/material";

const API_BASE_URL = "https://inifintusdev.tech/api";

const AddCredits = () => {
    const [wallets, setWallets] = useState([]);
    const {admin} = useContext(AdminContext);
    const [form, setForm] = useState({
        credits: 0,
        restaurantId: 1,
    });
    const [walletCredits, setWalletCredits] = useState(null);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure that credits and restaurantId are valid
        const {credits, restaurantId} = form;
        if (!credits || isNaN(parseFloat(credits)) || parseFloat(credits) <= 0) {
            alert("Please enter a valid positive number for credits.");
            return;
        }

        if (!restaurantId || isNaN(parseInt(restaurantId))) {
            alert("Please provide a valid restaurant ID.");
            return;
        }

        const updatedForm = {
            credits: parseFloat(credits), // Ensure credits is a number
        };

        try {
            const response = await axios.put(
                `${API_BASE_URL}/update_credits/${parseInt(restaurantId)}`,
                updatedForm,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            console.log("Credits updated successfully:", response.data);
            alert("تم اضافة رصيد");
            navigate("/admin/homepage");
        } catch (error) {
            console.error("Error submitting form:", error);

            // Improved error handling with specific messages
            if (error.response) {
                // Server responded with a status code outside the 2xx range
                alert(
                    `Error: ${
                        error.response.data.message || "Failed to update credits."
                    } (Status: ${error.response.status})`
                );
            } else if (error.request) {
                // Request was made but no response received
                alert(
                    "Error: No response from the server. Please check your network connection."
                );
            } else {
                // Something else happened while setting up the request
                alert("Error: Failed to submit the form. Please try again later.");
            }
        }
    };

    useEffect(() => {
        axios
            .get(`${API_BASE_URL}/get_wallets`) // Fetch only the restaurants that have wallets
            .then((response) => {
                if (response.data) {
                    setWallets(response.data);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleRestaurantChange = (e) => {
        setForm((prevForm) => ({
            ...prevForm,
            restaurantId: e.target.value,
        }));
    };

    if (!admin) {
        navigate("/admin");
        return null;
    }

    return (
        <div>

            <div style={{marginTop: 120}} className="container">
                <div style={{marginLeft: "530px"}}>
                    <div className="nav">
                        <Container
                            maxWidth="md"
                            sx={{
                                bgcolor: "background.default",
                                color: "text.primary",
                                padding: 3,
                                borderRadius: 2,
                                border: "solid black 1px",
                                mt: 8,
                            }}
                        > <FormLabel>صفحة اضافة رصيد</FormLabel>
                            <form onSubmit={handleSubmit}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="الرصيد"
                                        name="credits"
                                        value={form.credits}
                                        onChange={handleChange}
                                        variant="outlined"
                                        margin="normal"
                                        sx={{bgcolor: "background.paper", width: "100%"}}
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12} sm={6}>
                                        <FormControl margin="normal">
                                            <InputLabel id="restaurant-label">مطاعم</InputLabel>
                                            <Select
                                                labelId="restaurant-label"
                                                id="restaurantId"
                                                name="restaurantId"
                                                value={form.restaurantId}
                                                onChange={handleRestaurantChange}
                                                variant="outlined"
                                                sx={{width: "100%"}}
                                                fullWidth
                                            >
                                                <MenuItem value="">
                                                    <em>اختر مطعم</em>
                                                </MenuItem>
                                                {wallets.map((wallet) => (
                                                    <MenuItem key={wallet.id} value={wallet.restaurantId}
                                                              onClick={() => setWalletCredits(wallet.credits)}>
                                                        {wallet.restaurant.commercialName}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                            {walletCredits && (
                                                <Card sx={{mt: 2, p: 2, bgcolor: "background.paper", boxShadow: 3}}>
                                                    <Typography variant="h6" component="div" sx={{textAlign: "center"}}>
                                                        الرصيد الحالي: {walletCredits}
                                                    </Typography>
                                                </Card>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            sx={{height: "56px", width: "100%"}}
                                            variant="contained"
                                            color="success"
                                            type="submit"
                                        >
                                            اضافة رصيد
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCredits;
