import React, { useState } from "react";
import axios from "axios";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import logo from "../../Assets/Help Me444.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string()
        .required('Required')
        .min(3, 'Must be at least 3 characters')
        .max(50, 'Must be 50 characters or less'),
    device_uid: Yup.string()
        .required('Required')
        .min(3, 'Must be at least 3 characters')
        .max(50, 'Must be 50 characters or less'),
});

const AddDevice = () => {
    const baseUrl = "https://inifintusdev.tech/api"; // Replace with your actual base URL
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
            device_uid: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            axios.post(`${baseUrl}/create_device`, values)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("Device added successfully");
                        navigate(-1);
                    } else {
                        console.log("Error adding device");
                    }
                })
                .catch((error) => {
                    console.error("Error adding device:", error);
                });
        },
    });

    return (
        <div>
            <div style={{ marginTop: 120 }} className="container">
                <div className="content">
                    <div className="nav">
                        <Container
                            maxWidth="sm"
                            sx={{
                                mt: 5,
                                border: "1px solid black",
                                borderRadius: "15px",
                                padding: "20px",
                            }}
                        >
                            <Typography variant="h4" gutterBottom>
                                تعريف جهاز جديد
                            </Typography>
                            {/* <Button
                                sx={{ mb: "10px" }}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                عودة
                            </Button> */}

                            <form onSubmit={formik.handleSubmit}>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        id="name"
                                        name="name"
                                        fullWidth
                                        label="اسم الجهاز"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.name && formik.errors.name}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <TextField
                                        id="device_uid"
                                        name="device_uid"
                                        fullWidth
                                        label="معرف الجهاز"
                                        value={formik.values.device_uid}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.device_uid && formik.errors.device_uid}
                                        error={formik.touched.device_uid && Boolean(formik.errors.device_uid)}
                                        variant="outlined"
                                    />
                                </Box>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                >
                                    انشئ جهاز
                                </Button>
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDevice;
