import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const PricingPage = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all restaurants
    axios
      .get(
        "https://inifintusdev.tech/api/get_restaurants_pricing"
      )
      .then((response) => {
        setRestaurants(response.data);
      })
      .catch((error) => console.error("Error fetching restaurants:", error));
  }, []);

  const handleRestaurantChange = (e) => {
    const restaurantId = e.target.value;
    const restaurant = restaurants.find((r) => r.id === parseInt(restaurantId));
    setSelectedRestaurant(restaurant);
  };

  const handlePriceChange = (regionId, price) => {
    const updatedRestaurant = {
      ...selectedRestaurant,
      Pricing: selectedRestaurant.Pricing.map((p) => {
        if (p.regionId === regionId) {
          return {
            ...p,
            price: price,
          };
        }
        return p;
      }),
    };
    setSelectedRestaurant(updatedRestaurant);
  };

  const handleSave = () => {
    axios
      .post(
        "https://inifintusdev.tech/api/update_restaurant_pricing",
        {
          selectedRestaurant,
        }
      )
      .then((response) => {
        console.log("Pricing updated successfully:", response.data);
      })
      .catch((error) => console.error("Error updating pricing:", error));
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        صفحة التسعير
      </Typography>
      <Button
        sx={{ mb: "10px" }}
        variant="contained"
        onClick={() => navigate(-1)}
      >
        عودة
      </Button>
      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="restaurant-select-label">اختر مطعم</InputLabel>
          <Select
            labelId="restaurant-select-label"
            value={selectedRestaurant ? selectedRestaurant.id : ""}
            onChange={handleRestaurantChange}
          >
            <MenuItem value="">
              <em>اختر مطعم</em>
            </MenuItem>
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.commercialName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectedRestaurant && (
        <Box>
          <Typography variant="h5" gutterBottom>
            {selectedRestaurant.username} تعديل الاسعار ل
          </Typography>
          <Grid container spacing={2}>
            {selectedRestaurant.Pricing.map((price) => (
              <Grid item xs={2.4} key={price.regionId}>
                <TextField
                  label={price.region.value}
                  type="number"
                  value={price.price}
                  onChange={(e) =>
                    handlePriceChange(price.regionId, e.target.value)
                  }
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              حفظ
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default PricingPage;
