import React from 'react';
// import './Register.scss';
import { Card, CardContent, Typography, TextField, Button, CardActions } from '@mui/material';
import axios from 'axios';
const API_BASE_URL = "http://localhost:3000/";
import logo from "../../Assets/Help Me444.png";

function Register() {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        pictureUrl: '',
        email: '',
        location: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`http://localhost:3000/register`, formData);
            console.log(response.data);
        } catch (error) {
            console.error('Error registering user:', error);
        }
    };

    return (
        <div>
            <Card sx={{ borderRadius: '35px', backdropFilter: "blur", backgroundColor: 'rgba(0,0,30,0.4)' }} className='card'>
                <CardContent>
                    <Typography className='formLabel' variant="h5" component="div" >
                        Register
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            label="Username"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Picture URL"
                            name="pictureUrl"
                            value={formData.pictureUrl}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Phone"
                            name="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <CardActions>
                            <Button type="submit" size="large" variant="contained" fullWidth>
                                Submit
                            </Button>
                        </CardActions>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default Register;
