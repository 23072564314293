// RestaurantsPage.js
import React, {useState, useEffect} from "react";
import axios from "axios";
import RestaurantsTable from "./RestaurantsTable";
import {Container, Typography, Box, Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import logo from "../../Assets/Help Me444.png";

const RestaurantsPage = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [restaurantPage, setRestaurantPage] = useState(0);
    const [restaurantRowsPerPage, setRestaurantRowsPerPage] = useState(25);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await axios.get(
                    "https://inifintusdev.tech/api/get_restaurants"
                );
                setRestaurants(response.data);
            } catch (error) {
                console.error("Error fetching restaurants:", error);
            }
        };

        fetchRestaurants();
    }, []);

    const handleRestaurantChangePage = (event, newPage) => {
        setRestaurantPage(newPage);
    };

    const handleRestaurantChangeRowsPerPage = (event) => {
        setRestaurantRowsPerPage(parseInt(event.target.value, 10));
        setRestaurantPage(0);
    };

    const addRegions = () => {
        navigate("/admin/add-region");
    };
    const totalPrice = () => {
        navigate("/admin/total");
    };
    const taskPageButton = () => {
        navigate("/admin/homepage");
    };
    const createRestaurant = () => {
        navigate("/admin/add-restaurant");
    };

    
    return (
        <Container
            sx={{
                height: "auto",
                width: "80vw",
                mt: 20,
                border: "solid",
                borderRadius: "25px",
                padding: 2,
            }}
        >
            
            <Box display="flex" mb={3} mt={2} alignItems="center">
                <Typography variant="h4" sx={{flexGrow: 1, p: 1}}>
                    صفحة المطاعم
                </Typography>
                <Button
                    sx={{mr: 2}}
                    variant="contained"
                    color="secondary"
                    onClick={createRestaurant}
                >
                    انشئ مطعم
                </Button>
                <Button
                    sx={{mr: 2}}
                    variant="contained"
                    color="primary"
                    onClick={taskPageButton}
                >
                    قائمة المهام
                </Button>
            </Box>

            <RestaurantsTable
                restaurants={restaurants}
                restaurantPage={restaurantPage}
                restaurantRowsPerPage={restaurantRowsPerPage}
                handleRestaurantChangePage={handleRestaurantChangePage}
                handleRestaurantChangeRowsPerPage={handleRestaurantChangeRowsPerPage}
            />
        </Container>
    );
};

export default RestaurantsPage;
