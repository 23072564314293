import React, {useState} from "react";
import axios from "axios";
import {Container, Typography, TextField, Button, Box} from "@mui/material";
import logo from "../../Assets/Help Me444.png";
import {useNavigate} from "react-router-dom";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal} from "@mui/joy";
import {MapContainer, TileLayer, Marker, useMapEvents} from "react-leaflet";
import "leaflet/dist/leaflet.css";

const validationSchema = Yup.object({
    username: Yup.string()
        .required('Required')
        .min(3, 'Must be at least 3 characters')
        .max(15, 'Must be 15 characters or less')
        .matches(/^\S*$/, 'No spaces allowed'),
    password: Yup.string()
        .required('Required')
        .min(4, 'Must be at least 4 characters')
        .max(20, 'Must be 20 characters or less'),
    phoneNumber: Yup.string().required('Required')
        .matches(/^[0-9]+$/, "Must be only digits"),
    address: Yup.string().required('Required')
        // format is 31.5123123,35.1231321321312
        .matches(/^[0-9]+\.[0-9]+,[0-9]+\.[0-9]+$/, "Must be in the format 31.5123123,35.1231321321312"),
    commercialName: Yup.string().required('Required')
        .min(3, 'Must be at least 3 characters')
        .max(15, 'Must be 15 characters or less'),

    detailedAddress: Yup.string().required('Required')
        .min(10, 'Must be at least 10 characters')
        .max(100, 'Must be 100 characters or less'),
    photo: Yup.mixed().required('Required')
});
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const AddRestaurant = () => {
    const baseUrl = "https://inifintusdev.tech/api"; // Replace with your actual base URL
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedPosition, setSelectedPosition] = useState([31.5123123, 35.1231321321312]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                setSelectedPosition([e.latlng.lat, e.latlng.lng]);
                formik.setFieldValue("address", `${e.latlng.lat},${e.latlng.lng}`);
                handleClose();
            },
        });
        return selectedPosition ? (
            <Marker position={selectedPosition}></Marker>
        ) : null;
    };
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
            phoneNumber: '',
            address: '',
            commercialName: '',
            detailedAddress: '',
            photo: null,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // show loader
            const formData = new FormData();
            formData.append('username', values.username);
            formData.append('password', values.password);
            formData.append('phoneNumber', values.phoneNumber);
            formData.append('address', values.address);
            formData.append('commercialName', values.commercialName);
            formData.append('detailedAddress', values.detailedAddress);
            formData.append('photo', values.photo);

            axios.post(`${baseUrl}/create_restaurant`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log("Restaurant added successfully");
                        navigate(-1);
                    } else {
                        console.log("Error adding restaurant");
                    }
                })
                .catch((error) => {
                    console.error("Error adding restaurant:", error);
                });

        },
    });


    return (
        <div>
            <div style={{marginTop: 120}} className="container">
                <div className="content">
                    <div className="nav">
                        <Container
                            maxWidth="sm"
                            sx={{
                                mt: 5,
                                border: "1px solid black",
                                borderRadius: "15px",
                                padding: "20px",
                            }}
                        >
                            <Typography variant="h4" gutterBottom>
                                تعريف مطعم جديد
                            </Typography>
                            {/* <Button
                                sx={{mb: "10px"}}
                                variant="contained"
                                onClick={() => navigate(-1)}
                            >
                                عودة
                            </Button> */}

                            <form onSubmit={formik.handleSubmit}>
                                <Box sx={{mb: 3}}>
                                    <TextField
                                        id="username"
                                        name="username"
                                        fullWidth
                                        label="اسم المستخدم"
                                        value={formik.values.username}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.username && formik.errors.username}
                                        error={formik.touched.username && Boolean(formik.errors.username)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{mb: 3}}>
                                    <TextField
                                        id="password"
                                        name="password"
                                        fullWidth
                                        type="password"
                                        label="كلمة السر"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.password && formik.errors.password}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{mb: 3}}>
                                    <TextField
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        fullWidth
                                        label="رقم الهاتف"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{mb: 3}}>
                                    <TextField
                                        id="address"
                                        name="address"
                                        fullWidth
                                        label="العنوان كإحداثيات"
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.address && formik.errors.address}
                                        error={formik.touched.address && Boolean(formik.errors.address)}
                                        variant="outlined"
                                    />
                                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
                                        <Button variant="contained" onClick={handleOpen}>
                                            اختر من الخريطة
                                        </Button>
                                    </Box>
                                </Box>
                                <Modal open={open} onClose={handleClose}>
                                    <Box sx={{width: 1000, height: 800, margin: "auto", mt: 5}}>
                                        <MapContainer center={selectedPosition} zoom={13}
                                                      style={{height: "100%", width: "100%"}}>
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            />
                                            <LocationMarker/>
                                        </MapContainer>
                                    </Box>
                                </Modal>
                                <Box sx={{mb: 3}}>
                                    <TextField
                                        id="commercialName"
                                        name="commercialName"
                                        fullWidth
                                        label="الاسم التجاري"
                                        value={formik.values.commercialName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.commercialName && formik.errors.commercialName}
                                        error={formik.touched.commercialName && Boolean(formik.errors.commercialName)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{mb: 3}}>
                                    <TextField
                                        id="detailedAddress"
                                        name="detailedAddress"
                                        fullWidth
                                        label="العنوان كنص"
                                        value={formik.values.detailedAddress}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        helperText={formik.touched.detailedAddress && formik.errors.detailedAddress}
                                        error={formik.touched.detailedAddress && Boolean(formik.errors.detailedAddress)}
                                        variant="outlined"
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Button
                                        variant="contained"
                                        component="label"
                                        color="secondary"
                                        fullWidth
                                    >
                                        Upload Photo
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(event) => {
                                                formik.setFieldValue("photo", event.target.files[0]);
                                            }}
                                        />
                                    </Button>
                                    {formik.touched.photo && formik.errors.photo ? (
                                        <div>{formik.errors.photo}</div>
                                    ) : null}
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                >
                                    انشئ مطعم
                                </Button>
                            </form>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default AddRestaurant;
