import React, { useState } from 'react';
import '../navbar/Navbar.css';
import logo from "../../Assets/Help Me444.png";
import { Button, Tabs, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >

        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const NavBar = () => {
    const [clickedButton, setClickedButton] = useState(null);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    const handleButtonClick = (route) => {
        setClickedButton(route);
        navigate(route);
    };

    return (
        <nav>
            <div className="navbar-container">
                <ul className="navbar-menu">
                    <Tabs orientation="vertical" value={value}>
                        <Typography
                            sx={{ backgroundColor: "#6C757D", color: "black", mb: '4vh', borderRadius: '0px', fontWeight: "bolder", fontSize: '24px',height:'6vh', width: '7vw', display: 'flex', justifyContent: 'center' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/map")}
                            disabled={clickedButton === "/admin/map"}
                        >
                            القائمة
                        </Typography>
                        <Button

                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/map")}
                            disabled={clickedButton === "/admin/map"}
                        >
                            الخريطة
                        </Button>

                        <hr style={{
                            width: '7vw', border: '1px solid black',margin:0
                        }} />
                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/add-package")}
                            disabled={clickedButton === "/admin/add-package"}
                        >
                            انشئ طرد
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/add-task")}
                            disabled={clickedButton === "/admin/add-task"}
                        >
                            انشئ مهمه
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/pricing")}
                            disabled={clickedButton === "/admin/pricing"}
                        >
                            التسعير
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/add-region")}
                            disabled={clickedButton === "/admin/add-region"}
                        >
                            إضافة مناطق
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/total")}
                            disabled={clickedButton === "/admin/total"}
                        >
                            التحصيلات
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/drivers")}
                            disabled={clickedButton === "/admin/drivers"}
                        >
                            قائمة السائقين
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/restaurants")}
                            disabled={clickedButton === "/admin/restaurants"}
                        >
                            قائمة المطاعم
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            onClick={() => handleButtonClick("/admin/homepage")}
                            disabled={clickedButton === "/admin/homepage"}
                        >
                            قائمة المهام
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleButtonClick("/admin/add-device")}
                            disabled={clickedButton === "/admin/add-device"}
                        >
                            اضافة سيارة
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />

                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            color="success"
                            onClick={() => handleButtonClick("/admin/add-driver")}
                            disabled={clickedButton === "/admin/add-driver"}
                        >
                            إضافة سائق
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />
                    {/*    wallet*/}
                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            color="success"
                            onClick={() => handleButtonClick("/admin/create-wallet")}
                            disabled={clickedButton === "/admin/create-wallet"}
                        >
                            إنشاء محفظة
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />
                    {/*    wallet*/}
                    {/*    wallet*/}
                        <Button
                            sx={{ backgroundColor: "#FFD700", color: "black", borderRadius: '0px', fontWeight: "bolder",height:'5vh', width: '7vw' }}
                            variant="contained"
                            color="success"
                            onClick={() => handleButtonClick("/admin/add-credits")}
                            disabled={clickedButton === "/admin/add-credits"}
                        >
                            إضافة رصيد
                        </Button>
                        <hr style={{ width: '7vw', border: '1px solid black',margin:0 }} />
                    {/*    wallet*/}
                    </Tabs>
                </ul>
            </div>
            <img
                src={logo}
                style={{
                    position: "absolute",
                    display: "flex",
                    top: -70,
                    left: 30,
                    width: "12%",
                    height: "40%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
                alt="Logo"
            />
        </nav>
    );
};

export default NavBar;
